exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-chat-js": () => import("./../../../src/pages/app/chat.js" /* webpackChunkName: "component---src-pages-app-chat-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-app-news-details-js": () => import("./../../../src/pages/app/news_details.js" /* webpackChunkName: "component---src-pages-app-news-details-js" */),
  "component---src-pages-app-news-js": () => import("./../../../src/pages/app/news.js" /* webpackChunkName: "component---src-pages-app-news-js" */),
  "component---src-pages-app-paare-end-js": () => import("./../../../src/pages/app/paare_end.js" /* webpackChunkName: "component---src-pages-app-paare-end-js" */),
  "component---src-pages-app-paare-js": () => import("./../../../src/pages/app/paare.js" /* webpackChunkName: "component---src-pages-app-paare-js" */),
  "component---src-pages-app-spiele-js": () => import("./../../../src/pages/app/spiele.js" /* webpackChunkName: "component---src-pages-app-spiele-js" */),
  "component---src-pages-app-spiele-paare-js": () => import("./../../../src/pages/app/spiele_paare.js" /* webpackChunkName: "component---src-pages-app-spiele-paare-js" */),
  "component---src-pages-app-spiele-plakate-1-js": () => import("./../../../src/pages/app/spiele_plakate1.js" /* webpackChunkName: "component---src-pages-app-spiele-plakate-1-js" */),
  "component---src-pages-app-spiele-plakate-2-js": () => import("./../../../src/pages/app/spiele_plakate2.js" /* webpackChunkName: "component---src-pages-app-spiele-plakate-2-js" */),
  "component---src-pages-app-spiele-plakate-3-js": () => import("./../../../src/pages/app/spiele_plakate3.js" /* webpackChunkName: "component---src-pages-app-spiele-plakate-3-js" */),
  "component---src-pages-app-spiele-plakate-4-js": () => import("./../../../src/pages/app/spiele_plakate4.js" /* webpackChunkName: "component---src-pages-app-spiele-plakate-4-js" */),
  "component---src-pages-app-spiele-plakate-js": () => import("./../../../src/pages/app/spiele_plakate.js" /* webpackChunkName: "component---src-pages-app-spiele-plakate-js" */),
  "component---src-pages-avatar-js": () => import("./../../../src/pages/avatar.js" /* webpackChunkName: "component---src-pages-avatar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */)
}

